<template>
    <div>
        <div class="d-flex mb-2">
            <slot/>
            <label :for="id" v-if="title" class="ms-2"> {{$t(title)}} </label>
        </div>
        <div class="input-group">
            <div class="input-group-prepend">
                <div style="background: #fff" class="input-group-validation inputCheckCredentials">
                <semipolar-spinner :animation-duration="2000" :size="23" color="#ff1d5e" v-if="loading && !$v.credential.$invalid"/>
                <i :title="$t('NOT_AVAILABLE')"  v-if="isAvailable === false && !$v.credential.$error && !loading" style="color: #cb1100" class="icon icon-cross" ></i>
                <i :title="$t('checkout.available')" v-if="isAvailable === true && !$v.credential.$error && !loading"  style="color: rgb(93, 197, 150)" class="icon icon-checkmark"></i>
                </div>
            </div>
            <input type="text" autocomplete="off"  v-if="type==='identification'" :placeholder="$t(placeholder)"  v-model="$v.credential.$model" v-mask="'###-#######-#'" class="form-control inputCredentials" :class="{ 'is-invalid': $v.credential.$error || isAvailable === false }" :id="'input'+label">
            <input type="text" autocomplete="off" :disabled="isClient && !blockEmail"  v-if="type==='whatsapp'" v-model="$v.credential.$model" v-mask="'+1 (###) ###-####'" :placeholder="$t(placeholder)" class="form-control inputCredentials" :class="{ 'is-invalid': $v.credential.$error || isAvailable === false  }" :id="'input'+label">
            <input type="text" autocomplete="off" :disabled="(isClient && blockEmail) || disabled"  v-if="type==='email'" :placeholder="$t(placeholder)" v-model="$v.credential.$model" class="form-control inputCredentials" :class="{ 'is-invalid': $v.credential.$error || isAvailable === false }" :id="'input'+label">
            <input type="text" autocomplete="off" v-if="type === 'pasaporte'" v-model="$v.credential.$model" class="form-control inputCredentials" :class="{ 'is-invalid': $v.credential.$error || isAvailable === false }" :id="'input'+label" >
            <div class="invalid-feedback" v-if="!$v.credential.required" >{{ $t("common.required_field") }}</div>
            <template v-if="type==='identification'">
                <div v-if="!$v.credential.valida_cedula && $v.credential.required" class="invalid-feedback">{{ $t("checkout.please_enter_a_valid_identification_card") }}</div>
            </template>
            <template v-if="type === 'whatsapp'" >
                <div class="invalid-feedback" v-if="!$v.credential.phone" >
                {{ $t("auth.enter_a_phone_number_with_the_following_codes") }}
                809, 829, 849.
                </div>
            </template>
            <template  v-if="type === 'email'">
                <div class="invalid-feedback" v-if="!$v.credential.email" >{{ $t("auth.enter_a_valid_email") }}</div>
            </template>
            <template v-if="isAvailable === false && $route.path !== '/update'">
                <template v-if="$route.path === '/request'">
                    <div class="invalid-feedback">
                        {{ $t("auth.this_x_is_already_registered", {1: label}) }}
                    </div>
                </template>
                <template v-else>
                    <div v-if="this.web" class="invalid-feedback">
                        {{ $t("auth.this_x_is_already_registered", {1: type}) }}.
                    </div>
                    <div v-else class="invalid-feedback">
                        {{ $t("auth.this_x_has_a_customer_code", {1: type}) }}!
                    </div>
                </template>
            </template>
            <template v-if="isAvailable === false && $route.path === '/update'">
                <div class="invalid-feedback">
                    {{ $t("auth.this_x_does_not_match_any_of_our_records", {1: label}) }}
                </div>
            </template>
            </div>
        <div v-if="$v.credential.$error" class="invalid-feedback">
            {{ $t("common.required_field") }}
        </div>
    </div>
</template>
<script>

const codes = ["(829)", "(849)","(809)"];
import _  from "lodash"
import { required, minLength, email } from 'vuelidate/lib/validators'
import { VerificationService } from "../../services/verification-service";
const service = new VerificationService();
import { valida_cedula } from '../../helpers/global-methods'
import { SemipolarSpinner  } from 'epic-spinners'
export default {
    name: "MainInputVerify",
    components: {
        // ClipLoader: () => import('vue-spinner/src/ClipLoader'),
        SemipolarSpinner
    },
    props: {
        type: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        id:{
            type: String,
            required:true
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isClient: {
            type: Boolean,
            default: false
        },
        blockEmail: {
            type: Boolean,
            default: false
        },
        title:{
            type: String
        },
        placeholder:{
            type: String
        }
    },
    data(){
        return{
            credential: this.value,
            loading: true,
            existCredential: null,
            erp: false,
            web: false,
            isValidForUpdate: false,
            msg: "",
            customers_id: null,
            isAvailable: null,
            debounceInput: _.debounce( async function () {
        try{
            if (!this.$v.credential.$invalid){
                    this.customers_id = null;
                    this.isAvailable = null;
                    this.loading = true;
                    this.existCredential = null;
                    let credential = await this.filterCredential();
                    const data = await service.verifyCredentials(credential, this.type);
                    this.erp = (data.erp.validated === "true");
                    this.web = data.web;
                    this.customers_id = data.erp.customers_id;
                    this.isValidForUpdate = (data.erp.validated === "true");
                    this.existCredential =  (data.erp.validated === "true");
                if (this.$route.path === '/update'){
                    this.isAvailable = this.isValidForUpdate === false ? null : this.isValidForUpdate ;
                }else{
                if (this.$route.path === '/request'){
                    this.isAvailable = !this.existCredential
                }else{
                    this.isAvailable = (this.web === false && this.existCredential === false) || this.isClient;
                }
                }
                if (this.existCredential === false)
                this.$emit('block')

            }
        }catch (e) {
            this.existCredential = e;
        }finally {
            this.loading = false
        }
        }, 500)
        }
    },
    watch: {
        credential(){
            this.debounceInput()
            this.existCredential = null
        },

        isClient: {
        immediate: true,
        handler(){
            if (this.isClient){
            this.existCredential = true

            }
        }
        },
        type(){

        this.credential = ''
        },
        disabled(){

            this.credential = this.value;
            this.loading = true;
            this.existCredential = null;
            this.erp = false;
            this.web = false;
            this.isValidForUpdate = false;
            this.msg = "";
            this.customers_id = null;
            this.isAvailable = null;
        },
        value: {
        immediate: true,
        handler(){
            this.debounceInput()
        }

        }


    },
    validations() {
        switch (this.type) {
        case 'email':
            return{
            credential: {
                required,
                email
            }
            };
        case 'whatsapp':
            return{
            credential: {
                required,
                minLength: minLength(17),
                phone: function (val){
                let value = false, match = val.match(/[(]{0,1}[0-9]{1,4}[)]/)
                if (match){
                    value = codes.includes(match[0]);
                }
                return value
                }
            }
            };
        case 'identification':
            return{
            credential: {
                required,
                valida_cedula
            }
            };
        case 'pasaporte':
            return {
            credential: {
                required,
            }
            }
        }
    },
    methods: {
        async filterCredential(){
        switch (this.type) {
            case 'email':
            return this.credential;
            case 'whatsapp':
            return this.credential.replace(/[(||)| |+|-]/g, "");
            case 'identification':
            return this.credential.replace(/-/g,'');
            case 'pasaporte':
            return this.credential;
            default:
            throw new Error(this.$t('ERROR_IN_TYPE'));
        }
        },
    }
}
</script>

<style scoped>
    label{
        font-size: 14px;
        font-weight: 500;
        color: var(--main-navy); 
    }
    input{
        height: 38px !important;
        border-color: #cedde9 !important;
        border-radius: 0px 7px 7px 0px !important;
        border: 1px solid;
        width: 100%;
    }
    input:focus{
        box-shadow: none;
    }
    input::placeholder{
        font-size: 14px;
    }
    .input-group-validation{
        height: 38px !important;
        min-width: 35px;
        padding: 7px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        white-space: nowrap;
        border-bottom: 1px solid #cedde9;
        border-left: 1px solid #cedde9;
        border-top: 1px solid #cedde9;
        border-radius: 7px 0px 0px 7px !important;
    }
</style>