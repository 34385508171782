<template>
    <icon-base :width="size + 'px'" :fill="color">
        <path     
            d="M113.3,17.5v11.7C98,32.7,85.2,38.6,75.8,47.9C65.2,58.5,59.4,71.4,59.4,86.6c0,12.9,3.5,23.4,9.4,30.5  
            S82.8,130,91,133.5s18.8,7,31.6,10.5c9.4,2.3,16.4,4.7,21.1,5.9c4.7,1.2,8.2,3.5,10.5,5.9c2.3,2.3,3.5,4.7,3.5,8.2  
            c0,7-2.3,11.7-7,15.2c-5.9,3.5-12.9,5.9-23.4,5.9c-8.2,0-15.2-1.2-22.3-3.5c-7-2.3-14.1-5.9-22.3-10.5c-2.3-1.2-4.7-2.3-5.9-3.5 
            c-2.3-1.2-4.7-1.2-7-1.2c-3.5,0-7,1.2-11.7,5.9c-2.3,3.5-4.7,8.2-4.7,12.9c0,3.5,0,5.9,1.2,9.4c1.2,2.3,3.5,4.7,5.9,7  
            c7,5.9,15.2,10.5,24.6,14.1c8.2,2.3,16.4,4.7,24.6,5.9v10.5c0,4.7,1.2,9.4,4.7,12.9c3.5,3.5,8.2,4.7,12.9,4.7  
            c4.7,0,9.4-1.2,12.9-4.7c3.5-3.5,4.7-8.2,4.7-12.9v-11.7c15.2-3.5,27-9.4,36.3-18.8c10.5-10.5,15.2-23.4,15.2-37.5  
            c0-12.9-3.5-22.3-9.4-29.3c-5.9-7-12.9-12.9-22.3-16.4c-8.2-3.5-18.8-7-30.5-9.4c-9.4-2.3-16.4-4.7-22.3-5.9  
            c-4.7-1.2-8.2-3.5-11.7-7c-2.3-2.3-3.5-5.9-3.5-10.5c0-7,2.3-11.7,7-15.2c5.9-3.5,12.9-5.9,22.3-5.9c8.2,0,15.2,1.2,19.9,3.5  
            c5.9,2.3,11.7,5.9,18.8,10.5c3.5,2.3,4.7,3.5,7,3.5c2.3,1.2,3.5,1.2,5.9,1.2c3.5,0,7-1.2,11.7-5.9c2.3-3.5,4.7-8.2,4.7-12.9  
            c0-7-2.3-11.7-8.2-16.4c-11.7-9.4-25.8-16.4-42.2-18.8V17.5c0-4.7-1.2-9.4-4.7-12.9c-0.1-0.1-0.1-0.1-0.2-0.2  
            c-9.3-9-25.2-1.2-25.5,11.7C113.3,16.5,113.3,17,113.3,17.5z" class="st3">
        </path>
    </icon-base>
</template>
<script>

export default {
name: 'iconDollar',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#DCE4EA;}
    .st1{fill:#111820;}
</style>
