<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 512 512">
        <g>
            <g>
                <path d="M490.667,384h-21.333v-64c0-11.776-9.536-21.333-21.333-21.333V128h21.333c7.403,0,14.251-3.84,18.154-10.112    c3.882-6.294,4.246-14.144,0.939-20.757l-42.667-85.333C442.133,4.566,434.752,0,426.667,0H85.333    C77.248,0,69.867,4.566,66.24,11.797L23.573,97.13c-3.306,6.614-2.944,14.464,0.939,20.757C28.416,124.159,35.264,128,42.666,128    h21.333v170.667c-11.797,0-21.333,9.557-21.333,21.333v64H21.333C9.536,384,0,393.557,0,405.333v85.333    C0,502.443,9.536,512,21.333,512h469.333c11.797,0,21.333-9.557,21.333-21.333v-85.333C512,393.557,502.464,384,490.667,384z     M362.667,128h42.667v170.667h-42.667V128z M277.333,128H320v170.667h-42.667V128z M192,128h42.667v170.667H192V128z M106.667,128    h42.667v170.667h-42.667V128z M469.333,469.333H42.667v-42.667H64h384h21.333V469.333z"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconCompany',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
