<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 60 60">
        <g>
            <path d="M38.914,0H6.5v60h47V14.586L38.914,0z M39.5,3.414L50.086,14H39.5V3.414z M8.5,58V2h29v14h14v42H8.5z"/>
            <path d="M34.5,37c0.552,0,1-0.447,1-1s-0.448-1-1-1h-17c-0.552,0-1,0.447-1,1s0.448,1,1,1H34.5z"/>
            <path d="M44.5,30h-7c-0.552,0-1,0.447-1,1s0.448,1,1,1h7c0.552,0,1-0.447,1-1S45.052,30,44.5,30z"/>
            <path d="M21.5,31c0,0.553,0.448,1,1,1h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10C21.948,30,21.5,30.447,21.5,31z"/>
            <path d="M16.79,30.29c-0.18,0.189-0.29,0.449-0.29,0.71c0,0.26,0.11,0.52,0.29,0.7c0.18,0.189,0.44,0.3,0.71,0.3   s0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.261-0.11-0.521-0.29-0.71C17.84,29.92,17.15,29.93,16.79,30.29z"/>
            <path d="M38.79,35.29c-0.18,0.189-0.29,0.439-0.29,0.71c0,0.27,0.1,0.52,0.29,0.71C38.98,36.89,39.24,37,39.5,37   c0.26,0,0.52-0.11,0.71-0.29c0.19-0.19,0.29-0.44,0.29-0.71c0-0.261-0.11-0.521-0.29-0.71C39.83,34.92,39.17,34.92,38.79,35.29z"/>
            <path d="M43.79,35.29c-0.18,0.189-0.29,0.439-0.29,0.71c0,0.27,0.1,0.52,0.29,0.71C43.98,36.89,44.24,37,44.5,37   c0.26,0,0.52-0.11,0.71-0.3c0.19-0.19,0.29-0.44,0.29-0.7c0-0.261-0.11-0.521-0.29-0.71C44.83,34.92,44.17,34.92,43.79,35.29z"/>
            <path d="M23.025,40.166c-2.19,1.14-2.927,3.321-3.196,5.582c-0.414-0.347-0.828-0.693-1.242-1.04   c-0.98-0.821-2.402,0.586-1.414,1.415c0.935,0.783,1.871,1.567,2.806,2.351c0.658,0.551,1.676,0.203,1.707-0.707   c0.073-2.166,0.175-4.742,2.348-5.873C25.177,41.299,24.166,39.572,23.025,40.166z"/>
            <path d="M36.455,44.108c-1.458-0.092-3.592,2.155-4.716,0.153c-0.26-0.464-0.913-0.638-1.368-0.359   c-1.416,0.869-3.267,2.119-4.756,0.5c-0.873-0.949-2.285,0.468-1.414,1.414c1.87,2.033,4.276,1.415,6.399,0.263   c0.478,0.535,1.071,0.926,1.837,1.081c0.792,0.16,4.025-1.141,4.2-0.901c0.752,1.029,2.488,0.032,1.727-1.009   C37.847,44.543,37.371,44.166,36.455,44.108z"/>
            <path d="M28.666,23.963l0.674-0.479l-0.344-0.752c-0.312-0.682-0.813-1.212-1.45-1.532l-2.12-1.082   c0.975-0.623,1.676-1.561,2.095-2.801c0.684-0.417,1.115-1.158,1.115-1.984v-0.667c0-0.677-0.294-1.308-0.794-1.745   c-0.357-1.898-1.644-3.951-5.54-3.951c-0.153,0-0.303,0.006-0.451,0.018c-0.523,0.043-1.285,0-1.937-0.438   c-0.303-0.204-0.458-0.362-0.534-0.459c-0.317-0.403-0.849-0.544-1.324-0.35c-0.474,0.195-0.752,0.669-0.694,1.179   c0.03,0.257,0.073,0.557,0.138,0.884c0.084,0.42,0.089,0.541,0.086,0.573c-0.008,0.035-0.066,0.159-0.112,0.259   c-0.07,0.15-0.156,0.335-0.257,0.582c-0.217,0.529-0.375,1.105-0.471,1.719c-0.489,0.438-0.778,1.063-0.778,1.73v0.667   c0,0.826,0.431,1.567,1.115,1.984c0.417,1.235,1.115,2.171,2.083,2.793l-2.204,1.087c-0.613,0.334-1.091,0.867-1.382,1.541   l-0.32,0.741l0.656,0.469C17.797,25.291,20.004,26,22.302,26C24.592,26,26.792,25.296,28.666,23.963z M18.016,22.907l2.445-1.204   c0.519-0.257,0.842-0.776,0.842-1.355v-1.422l-0.604-0.261c-0.912-0.392-1.506-1.151-1.819-2.321l-0.143-0.533l-0.527-0.164   c-0.116-0.036-0.24-0.149-0.24-0.313v-0.667c0-0.142,0.095-0.242,0.184-0.289l0.469-0.25l0.055-0.529   c0.062-0.595,0.193-1.14,0.391-1.622c0.086-0.211,0.16-0.368,0.22-0.497c0.155-0.332,0.249-0.578,0.283-0.855   c0.73,0.305,1.559,0.425,2.44,0.358c0.095-0.008,0.192-0.012,0.291-0.012c2.919,0,3.469,1.334,3.622,2.638l0.061,0.523l0.466,0.245   c0.089,0.047,0.185,0.147,0.185,0.29v0.667c0,0.164-0.125,0.277-0.24,0.313l-0.527,0.164l-0.143,0.533   c-0.313,1.17-0.908,1.93-1.819,2.321l-0.604,0.261v1.428c0,0.57,0.315,1.086,0.825,1.347l2.415,1.233   C23.923,24.343,20.628,24.334,18.016,22.907z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconSurvey',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
