<template>
    <div class="row">
        <div class="col-md-4">
            <main-input :id="'company'" :title="'auth.company'" ref="company" @changeValue="(data)=>{form.company = data}">
            </main-input>
        </div>
        <div class="col-md-4">
            <main-input :id="'rnc'" :title="'auth.rnc'" ref="rnc" @changeValue="(data)=>{form.rnc = data}" :disabled="haveRnc">
            </main-input>
            <main-checkbox class="me-3 mt-3" :id="'have-rnc'" @checkboxChange="(data)=>{haveRnc = data}" :description="'auth.i_do_not_have'"/>
        </div>
        <div class="col-md-4">
            <main-input :id="'phone'" :title="'auth.phone_number'" ref="phone" @changeValue="(data)=>{form.telephone = data}" :placeholder="'+1 000-000-0000'" :mask="'+1 ###-###-####'">
            </main-input>
        </div>
    </div>
</template>

<script>
import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue'
import MainInput from '../../../../common/components/inputs/MainInput.vue'
export default {
    components: { MainInput, MainCheckbox },
    name: "Company",
    data() {
        return {
            form: {
                company: "",
                rnc: "",
                telephone: "",
            },
            haveRnc: false
        }
    },
    watch:{
        haveRnc(val){
            this.$emit('companyRnc', val)
        }
    }
}
</script>