<template>
    <div class="row justify-content-center m-0 position-relative">
        <div class="col-sm-4 col-4" v-for="(option, index) in options" :key="option.value" @click="$emit('select', option)" 
        :class="[index === 0? 'ps-0' : '', index === 2 ? 'pe-0' : '', register ? 'col-md-3 mt-3' : '', register && option.hide ? 'd-none' : '']">
            <div class="d-flex flex-column align-items-center container-svg cursor-pointer">
                <component :is="option.icon" :class="[ option.value === selectedOption.value || option.selected ? 'icon-stepper-active' : 'icon-stepper']"></component>
                <h6 class="title-16 d-flex mt-2" :class="[!option.selected ? 'color-gray-100' : '']" v-if="register">
                    <b class="d-none d-sm-block title-16" :class="[!option.selected ? 'color-gray-100' : '']"></b>{{$t(option.title)}}</h6>
                <h6 class="title-16 d-flex mt-2" :class="[!option.selected ? 'color-gray-100' : '']" v-else>
                    <b class="d-none d-sm-block title-16" :class="[!option.selected ? 'color-gray-100' : '']"> {{index + 1}}.</b>{{$t(option.title)}}</h6>     
                <div class="progress-bar d-none d-md-block">
                    <div :class="[ option.value === selectedOption.value || option.selected ? 'progress-bar-value' :'', empty(option) ? 'progress-empty' : '']"></div>
                </div>
            </div>
        </div>
        <div class="progress-mobile d-block d-md-none">
            <div :class="[paymentTab ? 'w-50' : '' , confirmationTab ? 'w-100' : '']"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconCompany from '../../../modules/auth/assets/svg/iconCompany.vue'
import IconSurvey from '../../../modules/auth/assets/svg/iconSurvey.vue'
import iconCheckoutAddress from '../../../modules/checkout/assets/svg/iconCheckoutAddress.vue'
import IconDollar from '../../../modules/checkout/assets/svg/iconDollar.vue'
import IconCheck from '../../svg/iconCheck.vue'
import IconPerson from '../../svg/iconPerson.vue'

export default {
    components: { iconCheckoutAddress, IconDollar, IconCheck, IconPerson, IconSurvey, IconCompany },
    name: "SecondNavTab",
    props:{
        options:{
            type: Array
        },
        selectedOption:{
            type: Object
        },
        selected:{
            type: Boolean
        },
        register:{
            type: Boolean
        }
    },
    data(){
        return{
            oldSelected: null
        }
    },
    computed:{
        ...mapGetters({
            addressTab: "checkout/addressTab",
            paymentTab: "checkout/paymentTab",
            confirmationTab: "checkout/confirmationTab",
        })
    },
    watch:{
        selectedOption(val, oldVal){
            this.oldSelected = oldVal
        }
    },
    methods:{
        empty(option){
            if(option.id == 1){
                return this.oldSelected?.id == 1 && option.id == 1 && this.selectedOption.id == 0
            }else if(option.id == 2){
                return this.oldSelected?.id == 2 && this.selectedOption.id == 1
            }
        }
    }
}
</script>

<style scoped>
.progress-bar {
    background: var(--gray-100);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    display: flex;
    height: 6px;
    width: 100%;
}

.progress-bar-value {
    animation: load 1s normal forwards;
    position: absolute;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #212e3d;
    height: 6px;
    width: 0;
    left: 0;
}
@keyframes load {
    0% { width: 0; }
    100% { width: 100%; }
}
.progress-empty{
    animation: empty 1s normal forwards;
    position: absolute;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #212e3d;
    height: 6px;
    width: 0;
    left: 0;
}
@keyframes empty {
    0% { width: 100%; }
    100% { width: 0%; }
}
.icon-stepper{
    stroke: #fff;
    background: var(--gray-100);
    fill: #fff !important;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 5px;

}
.icon-stepper-active{
    fill: #fff !important;
    background: var(--main-red);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 7px;
    stroke: #fff;

}
.container-svg svg{
    transition: var(--transition-1);
}
.container-svg:hover svg{
    background: var(--light-main-red) !important;
}
.container-svg:hover h6, .container-svg:hover h6 > b{
    color: var(--main-navy) !important;
}

.progress-mobile{
    position: absolute;
    width: 77%;
    height: 1px;
    z-index: -1;
    background: #dae5ea;
    top: 15px;
}
.progress-mobile > div{
    width: 0%;
    height: 2px;
    background: #111c22;
    transition: width 0.7s ease-in-out;
}
</style>
