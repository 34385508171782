<template>
    <main-modal :title="''" @closeModal="$emit('closeModal')" class="main-confirm">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <lottie :options="defaultOptions" :height="100" :width="200"/>
            <h5 class="mb-5 title-20">{{$t("auth.request_saved_successfully")}}</h5>
            <a href="/auth/request">
                <main-button :title="'auth.accept'" @click="$emit('closeModal')"/>
            </a>
        </div>
    </main-modal>
</template>

<script>
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import MainModal from '../../../../common/components/modals/MainModal.vue'
import Lottie from "vue-lottie";
import animationData from '../../assets/animations/save.json';
export default {
    components: { MainModal, MainButton, Lottie },
    name: "CreatedCompany",
    data() {
        return {
            defaultOptions: { animationData: animationData, loop: true },
        }
    },
}
</script>