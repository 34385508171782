<template>
    <div>  
        <h5 class="title-18 mb-0">{{$t("auth.please_enter_exact_addresses_so_we_can_provide_better_delivery_services")}}! {{$t("auth.with_our_easy_to_use_geo_location_will_make_deliveries_faster_order")}}</h5>
        <main-button :id="'open-modal'" @click="showModal = true" :title="'auth.click_here_to_add_addresses'" class="mt-3"/>
        <div :key="address.id"  v-for="address in addresses" class="col-lg-4 col-md-6 mt-3">
            <card>
                    <ul class="list-group p-1 " style="list-style: none;">
                        <li class="d-flex align-items-center justify-content-between subtitle-15" >
                            <div style="max-width: 200px" class="text-limited">
                                {{ address.address_line | textFormat }} 
                            </div>
                            <b v-if="address.default">{{ $t("account.default")}}<icon-star class="ms-3"/></b>
                        </li>
                        <li class="subtitle-15 text-limited"><span class="title-15 mb-0" >  {{ $t("account.reference") }}:</span> {{ address.reference | textFormat}}</li>
                        <li class="subtitle-15 text-limited"><span class="title-15 mb-0" >{{ $t("account.phone_number") }}:</span> {{ address.phone | phoneFormat }}</li>
                    </ul>
                    <ul class="list-group p-1" style="list-style: none;">
                        <li> 
                            <a href="#" @click.prevent="editAddress(address)" >{{ $t("account.edit") }}</a>
                        </li>
                        <li class="d-flex"> 
                            <a href="#" @click.prevent="deleteAddress(address)" class="">{{ $t("account.remove") }}</a>
                        </li>
                    </ul>
            </card>
        </div>
        <create-address v-if="showModal" @closeModal="showModal = false" :store="true" @address="addAddress"/>
    </div>
</template>
<script>
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import Card from '../../../../common/components/cards/Card.vue'
import CreateAddress from '../../../account/components/addresses/modals/CreateAddress.vue'
export default {
    components: { CreateAddress, MainButton, Card },
    name:"AddAddress",
    data() {
        return {
            showModal: false,
            addresses: []
        }
    },
    methods:{
        addAddress(data){
            this.addresses.push(data)
            this.showModal = false
            this.$emit("getAddresses", this.addresses)
            // localStorage.setItem("companyAddress", JSON.stringify(this.addresses))
        }
    }
}
</script>