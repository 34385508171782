<template>
    <div>
        <div class="option-container">
            <h5 class="mt-3 weight-600 title-18 mb-4">{{$t("auth.category")}}</h5>
            <div v-for="item in categories" :key="item.id" class="d-flex mt-2 ps-4 align-items-center" @click="category = item">
                <radio-button :checked="category.id === item.id"/>
                <span class="ms-3 title-16 mb-0">{{$t(item.title)}} <span class="subtitle-14 color-dark-gray-200">{{$t(item.content)}}.</span> </span>
            </div>
            <h5 class="mt-3 weight-600 title-18 mb-0">{{$t("auth.recurrence_of_purchases")}}</h5>
            <div v-for="item in frequencies" :key="item.title" class="d-flex mt-2 align-items-center ps-4" @click="frequency = item">
                <radio-button :checked="frequency.id === item.id"/>
                <span class="ms-3 title-16 mb-0">{{$t(item.title)}} <span class="subtitle-14 color-dark-gray-200">{{$t(item.content)}}</span> </span>
            </div>
            <h5 class="mt-3 weight-600 title-18 mb-0">{{$t("auth.description_question")}}?</h5>
            <div v-for="item in questions" :key="item.title" class="d-flex mt-2 align-items-center ps-4" @click="question = item">
                <radio-button :checked="question.id === item.id"/>
                <span class="ms-3 title-16 mb-0">{{$t(item.title)}} <span class="subtitle-14 color-dark-gray-200">{{$t(item.content)}}</span> </span>
            </div>
            <div class="mt-3">
                <span class="title-16 mb-0">{{$t("auth.average_monthly_purchase_from_all_your_suppliers")}}</span>
                <main-input :id="'puschase'" class="mt-3 ps-4" :title="'auth.enter_the_correct_amount'" :type="'number'" :placeholder="'RD$ 0.00'" ref="amountPerMonth" style="max-width: 290px;" @changeValue="(data)=>{amountPerMonth = data}">
                    <icon-dollar :size="15"/>
                </main-input>
            </div>
            <div class="mt-3">
                <span class="title-16 mb-0">{{$t("auth.name_of_the_person_who_attends_you")}}</span>
                <main-input :id="'employee'" class="mt-3 ps-4" :title="'auth.name'" style="max-width: 290px;" :disabled="person" @changeValue="(data)=>{employee = data}">
                    <icon-person :size="15"/>
                </main-input>
                <main-checkbox :id="'someone'" @checkboxChange="(data)=>{person = data}" class="mt-3 ps-4" :description="'auth.i_dont_have_someone_to_take_care_of_me'"/>
            </div>
        </div>
    </div>
</template>

<script>
import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue'
import MainInput from '../../../../common/components/inputs/MainInput.vue'
import RadioButton from '../../../../common/components/radio/RadioButton.vue'
import IconPerson from '../../../../common/svg/iconPerson.vue'
import IconDollar from '../../../checkout/assets/svg/iconDollar.vue'
export default {
    components: { RadioButton, MainInput, IconDollar, MainCheckbox, IconPerson },
    name: 'Questionnaire',
    data() {
        return {
            category: { content: null, id:0, title:null },
            frequency: { id: 0, title: null },
            question: { id: 0, title: null },
            categories: [
                { id: 1, title: 'auth.ambulante_in_detail', content: 'auth.description_ambulante_in_detail', hide: this.hideOptions},
                { id: 2, title: 'auth.ambulante_at_the_wholesal', content: 'auth.description_ambulante_at_the_wholesal', hide: this.hideOptions},
                { id: 3, title: 'auth.retail_store', content: 'auth.description_retail_store', hide: false},
                { id: 4, title: 'auth.wholesale_store', content: 'auth.description_wholesale_store', hide: false },
                // { id: 5, title: "Perchero", content: "(Vendedor ambulante, anda con sus articulos de venta en una percha)" },
            ],
            frequencies: [
                { id: 1, title: 'auth.daily' },
                { id: 2, title: 'auth.several_times_a_week' },
                { id: 3, title: 'auth.weekly' },
                { id: 4, title: 'auth.biweekly' },
                { id: 5, title: 'auth.monthly' }
            ],
            questions: [
                { id: 1, title: 'auth.to_have_an_inventory' },
                { id: 2, title: 'auth.by_order' },
            ],
            employee: null,
            person: false,
            amountPerMonth: '',
            partners: [],
        }
    },
    watch:{
        category(val){
            if(val.id === 3 || val.id === 4 ){
                this.$emit("showOptions", false)
            }else{
                this.$emit("showOptions", true)
            }
        }
    }
}
</script>