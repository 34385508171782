
import { parseResponse } from "../../common/helpers/responses/parser";
import axios from "axios"
export class VerificationService {

    constructor() {
        this.ajaxRequest = null;
        this.url = process.env.VUE_APP_MM_WEB_BACKEND_ENV
    }

    async verifyCredentials(credential, type, isAvailable, syncCode){
        try{
            if (this.ajaxRequest){
                (this.ajaxRequest.cancel())
            }
            this.ajaxRequest = axios.CancelToken.source();
            const verification  = await axios.post(this.url+'verifications/credentials', {
                credential,
                type,
                isAvailable,
                syncCode
            });
            const res = parseResponse(verification.data);
            return res.data;
        }catch (e) {

            if (axios.isCancel(e)) {
                console.log(e)
            } else {
                if (e.response){
                    throw parseResponse(e.response.data)
                }else{
                    throw e
                }
            }


        }

    }

}