<template>
    <div>
        <second-nav-tab :options="options" :selectedOption="selectedOption" @select="selectOptionTab" :register="true"/>
        <alert-warning class="mt-4 mb-0" style="padding: 2px 15px" :size="30" :show="showAlert" :title="alertTitle" @showAlert="showAlert = false"/>
        <div>
            <register-company class="mt-4" ref="generalInformation" :class="selectedOption.id === 0 ? '' : 'd-none'"/>
            <questionnaire :class="selectedOption.id === 1 ? '' : 'd-none'" ref="questionnaire" @showOptions="showOptions"/>
            <company class="mt-4" :class="selectedOption.id === 2 ? '' : 'd-none'" ref="company" @companyRnc="(data)=>{haveRnc = data}"/>
            <images class="mt-4" :class="selectedOption.id === 3 ? '' : 'd-none'" ref="images"/>
            <add-address class="mt-4" :class="selectedOption.id === 4 ? '' : 'd-none'" @getAddresses="(data)=>{addresses = data}"/>
            <policies  class="mt-4" :class="selectedOption.id === 5 ? '' : 'd-none'" @policies="(data)=>{isAggree = data}"/>
        </div>
        <created-company @closeModal="finished" v-if="finish"/>   
        <div class="d-flex mt-5" :class="[selectedOption.id != 0 ? 'justify-content-between' : 'justify-content-end']">
            <second-button :title="'checkout.back'" @click="back" v-if="selectedOption.id != 0" :icon="true">
                <icon-arrow :size="13" class="me-3" style="transform: rotate(180deg);"/>
            </second-button>
            <main-button :title="selectedOption.id === 5 ? 'checkout.finish' : 'checkout.next' " :icon="true" :iconRight="true" :disabled="false" @click="next">
                <icon-arrow :size="13" :color="'#fff'" class="ms-3"/>  
            </main-button>
        </div>
        <transaction-error @closeModal="error = false" v-if="error"/>
    </div>
</template>
<script>
import MainButton from '../../../common/components/buttons/MainButton.vue'
import SecondButton from '../../../common/components/buttons/SecondButton.vue'
import SecondNavTab from '../../../common/components/tabs/SecondNavTab.vue'
import IconArrow from '../../../common/svg/iconArrow.vue'
import AddAddress from '../components/register-company/AddAddress.vue'
import RegisterCompany from '../components/register-company/Information.vue'
import Policies from '../components/register-company/Policies.vue'
import Questionnaire from '../components/register-company/Questionnaire.vue'
import { http } from "../../../common/services/web-backend";
import Company from '../components/register-company/Company.vue'
import Images from '../components/register-company/Images.vue'
import CreatedCompany from '../components/register-company/CreatedCompany.vue'
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue'
import TransactionError from '../components/register-company/TransactionError.vue'

export default {
    components: { RegisterCompany, SecondNavTab, SecondButton, MainButton, IconArrow, Questionnaire, AddAddress, Policies, Company, Images, CreatedCompany, AlertWarning, TransactionError },
    name: 'Request',
    data() {
        return {
            options: [
                {
                    id: 0,
                    value:'information',
                    title: 'auth.information',
                    icon: 'iconPerson',
                    hide: false,
                    selected:  true
                },
                {
                    id: 1,
                    value:'questionnaire',
                    title: 'auth.questionnaire',
                    icon: 'iconSurvey',
                    hide: false,
                    selected:  false

                },
                {
                    id: 2,
                    value:'company',
                    title: 'auth.company',
                    icon: 'iconCompany',
                    hide: true,
                    selected:  false

                },
                {
                    id: 3,
                    value:'images',
                    title: 'auth.images',
                    icon: 'iconSurvey',
                    hide: true,
                    selected:  false

                },
                {
                    id: 4,
                    value:'addresses',
                    title: 'auth.addresses',
                    icon: 'iconCheckoutAddress',
                    hide: false,
                    selected:  false
                },
                {
                    id: 5,
                    value:'policies',
                    title: 'auth.policies',
                    icon: 'IconCheck',
                    hide: false,
                    selected:  false
                }
            ],
            selectedOption: {
                id: 0,
                value:'information',
                title: 'auth.information',
                icon: '',

            },
            form: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                phone2: "",
                identification: ''
            },
            questionnaire: {
                category: null,
                frequency: null,
                question: null,
                amountPerMonth: null,
                partners: null,
                employee: null
            },
            company: {
                company: null,
                rnc: null,
                telephone: null
            },
            haveRnc: false,
            addresses:[],
            images: [],
            isAggree: false,
            finish: false,
            pictures: null,
            showAlert: false,
            alertTitle: '',
            error: false
        }
    },
    methods:{
        selectOptionTab(){
            if(this.selectedOption.id === 0){
                this.$refs.generalInformation.$refs.phone.$v.$touch()
                this.$refs.generalInformation.$refs.identification.$v.$touch()
                this.$refs.generalInformation.$refs.email.$v.$touch()
                this.$refs.generalInformation.$refs.name.$v.$touch()
                this.$refs.generalInformation.$refs.lastName.$v.$touch()
    
                // get data
                this.form.first_name = this.$refs.generalInformation.form.firstName
                this.form.last_name = this.$refs.generalInformation.form.lastName
                this.form.email = this.$refs.generalInformation.$refs.email.credential
                this.form.phone = this.$refs.generalInformation.$refs.phone.credential
                this.form.phone2 = this.$refs.generalInformation.$refs.phone2.credential
                this.form.identification = this.$refs.generalInformation.$refs.identification.credential
                this.images = this.$refs.generalInformation.images
                if (!this.$refs.generalInformation.noEmail) {
                    this.$refs.generalInformation.$refs.email.$v.$touch()
                    if(this.$refs.generalInformation.$refs.email.$v.$invalid){
                        return false
                    }
                }
                if(this.$refs.generalInformation.$refs.name.$v.$invalid || this.$refs.generalInformation.$refs.lastName.$v.$invalid || this.$refs.generalInformation.$refs.identification.$v.$invalid
                    || this.$refs.generalInformation.$refs.phone.$v.$invalid){
                        return false
                    }else{
                        if(this.images.length === 0){
                            this.showAlert = true
                            this.alertTitle = 'auth.please_upload_the_image_your_identification'
                            return false
                        }else{
            //                 let data = {
            //                     images: this.images,
            //                     form: this.form
            //                 }
            //                 localStorage.setItem("company", JSON.stringify(data))
                            return true
                        }
                    }
                }
            else if(this.selectedOption.id === 1){
                this.questionnaire.category = this.$refs.questionnaire.category.title
                this.questionnaire.frequency = this.$refs.questionnaire.frequency.title
                this.questionnaire.question = this.$refs.questionnaire.question.title
                this.questionnaire.amountPerMonth = this.$refs.questionnaire.amountPerMonth
                this.questionnaire.partners = this.$refs.questionnaire.partners
                this.questionnaire.employee = this.$refs.questionnaire.employee
                this.$refs.questionnaire.$refs.amountPerMonth.$v.$touch()
                if(!this.questionnaire.category || !this.questionnaire.frequency || !this.questionnaire.question  || this.$refs.questionnaire.$refs.amountPerMonth.$v.$invalid ){
                    return false
                }
                // let data = {
                //     images: this.images,
                //     form: this.form,
                //     questionnaire: this.questionnaire
                // }
                // localStorage.setItem("company", JSON.stringify(data))
                return true
            }else  if(this.selectedOption.id === 2){
                this.$refs.company.$refs.company.$v.$touch()
                this.$refs.company.$refs.phone.$v.$touch()
                if(!this.haveRnc){
                    this.$refs.company.$refs.rnc.$v.$touch()
                    if(this.$refs.company.$refs.rnc.$v.$invalid){
                        return false
                    }
                }
                if(this.$refs.company.$refs.company.$v.$invalid || this.$refs.company.$refs.phone.$v.$invalid){
                    return false
                }
                this.company.company = this.$refs.company.form.company
                this.company.rnc = this.$refs.company.form.rnc
                this.company.telephone = this.$refs.company.form.telephone

            }else if(this.selectedOption.id === 3){
                this.pictures = this.$refs.images.images

            }
            else if(this.selectedOption.id === 4){
                console.log(this.addresses.length > 0);
                if(this.addresses.length > 0){
                    // let data = {
                    //     images: this.images,
                    //     form: this.form,
                    //     questionnaire: this.questionnaire,
                    //     addresses: this.addresses
                    // }
                    // localStorage.setItem("company", JSON.stringify(data))
                    return true
                } else{
                    this.alertTitle = 'auth.please_enter_at_least_one_address'
                    this.showAlert = true
                    return false
                }
            }else if(this.selectedOption.id === 5){
                if(this.isAggree){
                    return true
                }else{
                    this.alertTitle = 'auth.you_must_accept_the_policies_to_continue'
                    this.showAlert = true
                    return false
                }
            }
            return true
        },
        next(){
            const validation = this.selectOptionTab(this.selectedOption)
            if(!validation){
                return;
            }
            window.scrollTo(0,0)
            if(this.selectedOption.id === 0){
                this.options[1].selected = true
                this.selectedOption = this.options[1]
                return
            }else if(this.selectedOption.id === 1){
                if(this.options[2].hide){
                    this.options[4].selected = true
                    this.selectedOption = this.options[4]
                }else{
                    this.options[2].selected = true
                    this.selectedOption = this.options[2]
                }
                return
            }else if(this.selectedOption.id === 2){
                this.options[3].selected = true
                this.selectedOption = this.options[3]
                return
            }else if(this.selectedOption.id === 3){
                this.options[4].selected = true
                this.selectedOption = this.options[4]
            }else if(this.selectedOption.id === 4){
                this.options[5].selected = true
                this.selectedOption = this.options[5]
            }
            else if(this.selectedOption.id === 5){
                this.finishForm()
            }

        },
        back(){
            if(this.selectedOption.id === 1){
                this.selectedOption = this.options[0]
                this.options[1].selected = false
            }else if(this.selectedOption.id === 2){
                this.options[2].selected = false
                this.selectedOption = this.options[1]
            }else if(this.selectedOption.id === 3){
                this.options[3].selected = false
                this.selectedOption = this.options[2]
            }else if(this.selectedOption.id === 4){
                if(this.options[2].hide){
                    this.options[1].selected = true
                    this.options[4].selected = false
                    this.selectedOption = this.options[1]
                }else{
                    this.options[4].selected = false
                    this.selectedOption = this.options[3]
                }
            }else if(this.selectedOption.id === 5){
                this.options[5].selected = false
                this.selectedOption = this.options[4]
            }
            window.scrollTo(0,0)

        },
        async formatData(){
            let formdata = new FormData();
            if (this.images.length){
                formdata.append('identification', this.images[0].file);
            }
            formdata.append('basic_information', JSON.stringify(this.form));
            if(this.questionnaire.category === 'auth.retail_store' || this.questionnaire.category === 'auth.wholesale_store'){
                formdata.append('company_information', JSON.stringify(this.company));

            }
            formdata.append('addresses', JSON.stringify(this.addresses));
            formdata.append('questionnaire_information', JSON.stringify(this.questionnaire));
            formdata.append('is_updated', JSON.stringify(this.$route.path === "/update"));
            formdata.append('origin', JSON.stringify(this.$route.path))
            if (this.pictures !== null){
                for (let i = 0; i < this.pictures['external'].length; i++) {
                formdata.append('images_external[' + i + ']', this.pictures['external'][i].file, this.pictures['external'][i].name);
                }
                for (let i = 0; i < this.pictures['internal'].length; i++) {
                formdata.append('images_internal[' + i + ']', this.pictures['internal'][i].file, this.pictures['internal'][i].name);
                }
            }
            return formdata
        },
        async finishForm() {
            try {
                this.$store.commit("common/SET_STATE_LOADING", true)
                const config = { headers: { 'content-type': 'multipart/form-data' } };
                let formdata = await this.formatData();
                await http.post('requests', formdata, config);
                this.finish = true;
            }catch (e) {
                console.log(e);
                this.error = true
            }finally {
                this.$store.commit("common/SET_STATE_LOADING", false)
                this.showModal = false
            }
        },
        showOptions(data){
            this.options[2].hide = data
            this.options[3].hide = data
        },
        finished(){
            this.$forceUpdate();
            this.finish = false
        },
        reload(){
            this.$forceUpdate();
        }
    }
}
</script>