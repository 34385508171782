<template>
    <div>
        <p>
            Por medio del presente formulario <span class="text-dark">CERTIFICO Y DOY F&Eacute; QUE</span>,
            el siguiente formulario contiene las informaciones tal cual prevalecen en mis documentos de identidad,
            la cual pueden ser utilizada para su debida verificaci&oacute;n.
        </p>
        <div class="d-flex align-items-center">
            <main-checkbox class="me-3" :id="'policies'" @checkboxChange="(data)=>{isAggree = data}"/>
            <span class="me-3">{{$t("auth.i_agree_with_the")}} <a href="/home/policies/view-privacy" target="_blank">{{$t("auth.policies")}}</a> </span>
        </div>
    </div>
</template>

<script>
import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue'
export default {
    components: { MainCheckbox },
    name: "Policies",
    data() {
        return {
            isAggree: false
        }
    },
    watch:{
        isAggree(val){
            this.$emit("policies", val)
        }
    }
}
</script>